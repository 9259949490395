<template class="footer-template">
  <div class="footer-component">
    <h1>Tuân Thủ</h1>
    <h3>Vui Lòng Đọc Kỹ Tuyên Bố Tuân Thủ Dưới Đây</h3>
    <p>Website này được sở hữu và điều hành bởi Doo Prime Vanuatu Limited.</p>
    <p>
      Trang web có thể được truy cập trên toàn cầu và không dành riêng cho bất
      kỳ thực thể nào. Điều này chủ yếu là để thuận tiện cho việc hiển thị tập
      trung và so sánh thông tin. Các quyền và nghĩa vụ thực tế của người dùng
      được xác định dựa trên tư cách pháp nhân và khu vực tài phán được quy
      định.
    </p>
    <p>
      Bạn phải từ 18 tuổi trở lên thì mới đủ điều kiện truy cập vào các sản phẩm
      và dịch vụ trên trang web của chúng tôi. Khi truy cập vào trang web, hãy
      bạn xác nhận rằng bạn là một cá nhân đủ tuổi và sở hữu đủ năng lực dân sự.
    </p>
    <p>
      Luật và quy định địa phương hiện hành có thể cấm hoặc hạn chế quyền của
      bạn trong việc truy cập, tải xuống, phân phối, phổ biến, chia sẻ hoặc sử
      dụng các tài liệu và thông tin được công bố trên trang web.
    </p>
    <a-collapse v-model:activeKey="activeKey" expandIconPosition="right" ghost="1">
      <a-collapse-panel key="1" header="Công Khai Rủi Ro Với Những Sản Phẩm Giao Dịch Mang Tính Rủi Ro Cao">
        <p>
          Chứng khoán, Hợp đồng tương lai, CFD và các sản phẩm tài chính khác
          đều mang tính rủi ro cao bởi sự biến động về giá trị và giá cả của các
          công cụ tài chính cơ bản. Do những biến động thị trường bất lợi và
          không thể đoán trước, nhà giao dịch có thể phải chịu những khoản lỗ
          lớn vượt quá mức đầu tư ban đầu trong một thời gian ngắn.
        </p>
        <p>
          Hiệu quả hoạt động trong quá khứ của một khoản đầu tư không phải là dấu
          hiệu cho thấy hiệu quả hoạt động của khoản đầu tư đó trong tương lai.
          Vui lòng đọc các nguyên tắc hiện hành trên trang web của chúng tôi.
        </p>
        <p>
          Vui lòng đảm bảo rằng bạn hiểu đầy đủ các rủi ro khi giao dịch với
          công cụ tài chính tương ứng trước khi tham gia vào bất kỳ giao dịch
          nào với chúng tôi. Bạn nên tìm kiếm lời khuyên chuyên môn độc lập nếu
          bạn không hiểu những rủi ro được giải thích ở đây.
        </p>
      </a-collapse-panel>
      <a-collapse-panel key="2" header="Thông Báo Liên Quan Đến Thương Hiệu Và Tổ Chức">
        <p>
          Doo Prime, Doo Financial, Peter Elish, Doo Clearing, Doo Tech, Doo
          Consulting và Doo Exchange là các tên giao dịch, nhãn hiệu và tài sản
          trí tuệ của Doo Group.
        </p>
        <p>
          Chúng tôi có nhiều tổ chức được quản lý và cấp phép trong các khu vực
          pháp lý khác nhau nhằm cung cấp các dịch vụ tài chính đa dạng. Bạn có
          thể thiết lập mối quan hệ kinh doanh với bất kỳ tổ chức nào của chúng
          tôi. Xin lưu ý việc lựa chọn tổ chức mang ý nghĩa rằng tài khoản giao
          dịch, hoạt động giao dịch và quỹ cá nhân sẽ chỉ được quản lý và điều
          chỉnh bởi cơ quan quản lý tương ứng.
        </p>

        <p>
          Chúng tôi không cung cấp dịch vụ của mình cho các cá nhân hoặc công ty
          thuộc một số khu vực pháp lý nhất định, bao gồm nhưng không giới hạn ở
          Afghanistan, Azerbaijan, Bosnia và Herzegovina, Burundi, Cameroon,
          Canada, Cộng hòa Trung Phi, Chad, Congo (Brazzaville), Congo
          (Kinshasa), Cuba , Equatorial Guinea, Eritrea, Ethiopia,
          Guinea-Bissau, Guyana, Haiti, Hong Kong, Iran, Iraq, Israel, Lebanon,
          Libya, Malaysia, Mali, Nicaragua, North Korea, Singapore, Somalia,
          South Sudan, Sri Lanka, Sudan, Syria, Tajikistan, Trinidad và Tobago,
          Tunisia, Turkmenistan, Các Tiểu vương quốc Ả Rập Thống nhất, Hoa Kỳ,
          Venezuela, Yemen, Zimbabwe. Thông tin và dịch vụ trên trang web của
          chúng tôi không được áp dụng và sẽ không được cung cấp cho quốc gia
          hoặc khu vực tài phán nơi việc phân phối thông tin và dịch vụ đó trái
          với quy chế và quy định của địa phương tương ứng. Khách truy cập từ
          các khu vực trên nên xác nhận xem quyết định đầu tư vào dịch vụ của
          chúng tôi có phù hợp với quy chế và quy định tại quốc gia hoặc khu vực
          tài phán của bạn hay không trước khi bạn sử dụng dịch vụ của chúng
          tôi. Chúng tôi có quyền thay đổi, sửa đổi hoặc ngừng các sản phẩm và
          dịch vụ của chúng tôi bất kỳ lúc nào.
        </p>
      </a-collapse-panel>
      <a-collapse-panel key="3" header="Thông Báo Đến Quý Khách Hàng" class="test">
        <p>
          Bất kỳ ký hiệu giao dịch nào được hiển thị ở đây chỉ dành cho mục đích minh họa và sẽ không cấu thành bất kỳ
          lời khuyên hoặc khuyến nghị nào của chúng tôi. Mọi nhận xét, tuyên bố, dữ liệu, thông tin, tài liệu hoặc tài
          liệu của bên thứ ba (“Tài liệu”) được cung cấp trên trang web này chỉ nhằm mục đích tham khảo. Tài liệu chỉ
          được sử dụng cho mục đích truyền thông tiếp thị và không chứa, và sẽ không được hiểu là lời khuyên đầu tư và /
          hoặc khuyến nghị đầu tư cho bất kỳ giao dịch nào. Mặc dù chúng tôi đã nỗ lực hết sức hợp lý để đảm bảo tính
          chính xác và đầy đủ của thông tin, nhưng chúng tôi không đại diện và bảo đảm cho Tài liệu và sẽ không chịu
          trách nhiệm đối với bất kỳ tổn thất nào, bao gồm nhưng không giới hạn ở việc mất lợi nhuận, tổn thất trực tiếp
          hoặc gián tiếp hoặc thiệt hại đối với bất kỳ sự không chính xác và không đầy đủ nào từ thông tin được cung
          cấp. Bạn sẽ chỉ sử dụng Tài liệu cho mục đích cá nhân và không được sao chép, sao chép, phân phối lại và /
          hoặc cấp phép cho Tài liệu mà không có sự đồng ý của chúng tôi.
        </p>
        <p>
          Chúng tôi sử dụng cookie trên trang web của mình để tùy chỉnh thông tin và trải nghiệm hiển thị trên trang web
          dựa trên sở thích của bạn. Bằng cách truy cập vào trang web này, bạn xác nhận rằng bạn đã đọc và đồng ý với
          các chi tiết trên và đồng ý việc sử dụng cookie của chúng tôi.
        </p>
        <p>
          Chúng tôi tuân thủ nghiêm ngặt tất cả các luật và quy định hiện hành tại các khu vực tài phán. Bạn có trách
          nhiệm xác định và đảm bảo rằng khoản đầu tư của bạn đáp ứng các yêu cầu của bạn. Bạn cam kết chịu mọi hậu quả
          từ các hoạt động đầu tư và giao dịch của mình.
        </p>
      </a-collapse-panel>
    </a-collapse>
  </div>
  <div class="lower-footer">
    <p>
      Trang web này được sở hữu và điều hành bởi Doo Prime Vanuatu Limited (Công
      ty số 700238). © 2022 Doo Prime. Đã đăng ký Bản quyền.
    </p>
  </div>
</template>

<script>
import "ant-design-vue/dist/antd.css";
export default {
  name: "footerComponent",
  data() {
    return {
      activeKey: "0",
    };
  },
};
</script>

<style lang="less">
.footer-component {
  text-align: left;
  width: 80%;
  margin: auto;
  background-color: #000018;
  padding-bottom: 16px;
  border-bottom: white solid 1px;

  .ant-collapse>.ant-collapse-item>.ant-collapse-header {
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 1.5px;
    color: rgba(255, 255, 255, 0.95);
    background-color: #000018;
    border-top: 1px rgba(255, 255, 255, 0.15) solid;
    padding-bottom: 16px;
    padding-left: 0px;

    .test {
      border-bottom: #fff solid 1px;
    }
  }

  .ant-collapse-content>.ant-collapse-content-box {
    background-color: #000018;
    padding-left: 0px;
  }

  h1 {
    border-top: #fff solid 1px;
    padding-top: 24px;
    font-family: Roboto;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: 1.93px;
    color: rgba(255, 255, 255, 0.95);
    padding-bottom: 26px;
    @media screen and (max-width: 767px) {
      font-size: 18px;
    }
  }

  h3 {
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 1.5px;
    color: rgba(255, 255, 255, 0.95);
    padding-bottom: 19px;
  }

  p {
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 1.5px;
    color: #fff;
  }
}

.lower-footer {
  text-align: center;
  height: 41px;
  margin-top: 69px;
  background-color: #131329;
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1.5px;
  text-align: center;
  color: rgba(255, 255, 255, 0.95);
  padding-top: 12px;
}

@media screen and (max-width: 767px) {
  .lower-footer {
    height: auto;
    background-color: #131329;
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 1.5px;
    text-align: center;
    color: rgba(255, 255, 255, 0.95);
    margin-top: 16px;

    p {
      margin: auto;
      text-align: center;
      width: 335px;
      font-family: Roboto;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 1.5px;
      text-align: center;
      color: #fff;
      padding: 15px 20px 12px 20px;
    }
  }
}
</style>
