<template>
    <!--p>
        <button @click="showModal">
            Show modal
        </button>
    </p -->
    <Modal v-model="isShow" :close="closeModal">
        <!--div class="dimmed-bg" @click="$emit('close-modal')"></div-->
        <div class="modal">
            <h1 v-if="formStatus">Cảm ơn bạn đã đăng ký Chúng tôi sẽ liên hệ với bạn ngay khi có thể.</h1><h1 v-else>oopsies</h1>
            <!--button @click="closeModal">
                close
            </button -->
        </div>
    </Modal>
</template>

<script>
    import { ref } from 'vue'
    export default {
        name : 'ModalComponent',
        props: ['formStatus'],
        data() {
            return {
                // isShow: ref(false)
                isShow: ref(false)
            }
        },
        methods: {
            showModal() {
                this.isShow = true
            },
            closeModal() {
                this.isShow = false
            }
        },
        mounted() {

        }
    }
</script>

<style scoped lang="less">
    .modal {
        width: 300px;
        padding: 73px 20px;
        box-sizing: border-box;
        background-color: #fff;
        font-size: 20px;
        text-align: center;
        font-size: 20px;
        border-radius: 10px;
        h1 {
            font-size: 20px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #313131;
            margin-bottom: 0;
        }
    }
</style>