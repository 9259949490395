<template>
<section class="informatics">
    <div>
    <Info />
    <Global/>
    <Partner/>
    <Funds/>
    </div>
</section>
</template>

<script>

import Info from './Info.vue'
import Global from './Global.vue'
import Partner from './Partners.vue'
import Funds from './Funds.vue'

export default {
    name: 'InformaticsPage',
    components: {
        Info,
        Global,
        Partner,
        Funds
    }
}
</script>

<style>

.informatics{
    background: #000018;
}
</style>