import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        redirect: { name: 'landingBrand' },
        component: Home,
    },
    {
        path: '/vn/landingbrand', // use alias to redirect / to /vn/.....
        name: 'landingBrand',
        component: Home
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

export default router