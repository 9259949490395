<template>
  <div class="global-section">
    <div class="global_header">
      <h1 id="Giới-Thiệu">Về Doo Prime</h1>
      <p>
        Doo Prime là nhà môi giới trực tuyến uy tín hàng đầu thế giới trực thuộc tập đoàn Doo Group, cung cấp cho các
        nhà đầu tư chuyên nghiệp nhiều sản phẩm CFD trên toàn cầu, với các trung tâm điều hành ở Dallas, Sydney,
        Singapore, Hong Kong, Dubai và Kuala Lumpur.
      </p>
    </div>
    <div class="container">
      <div class="list">
        <div class="wow fadeInUp top-element" data-wow-delay="0.2s">
          40,000 +<span class="text">Khách hàng trên khắp thế giới</span>
        </div>
        <div class="icon-box web middle-element"></div>
        <div class="wow fadeInUp " data-wow-delay="0.4s">
          500 +<span class="text text-adj">Giới tinh hoa trong ngành từ hơn 10 thành
            phố</span>
        </div>
        <div class="icon-box web"></div>
        <div class="wow fadeInUp bottom-element" data-wow-delay="0.6s">
          250 +<span class="text text-adj">Khách hàng doanh nghiệp</span>
        </div>
      </div>
      <div class="global-img">
        <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo-ahgd/37-window.svg" />
      </div>
      <div class="global-img-mobile">
        <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo-ahgd/37-window.svg" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GlobalSection",
};
</script>

<style lang="less">
.global-section {
  img {
    width: 100%;
  }

  .global-img-mobile {
    display: none;
  }

  .global_header {
    h1 {
      font-size: 48px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
      margin-top: 115px;
      margin-bottom: 16px;
    }

    p {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: 1.71px;
      text-align: center;
      color: #fff;
      width: 90%;
      text-align: center;
      margin: auto;
      margin-bottom: 43px;

      @media screen and (max-width: 834px) {
        width: 100%;
      }
    }
  }

  .list {
    max-width: 100%;
    width: 100%;
    margin: auto;
    margin-bottom: 70px;

    div {
      &:not(.icon-box) {
        display: inline-block;
        font-size: 60px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
        padding: 0 40px;

        
      }

      &.icon-box {
        width: 10px;
        height: 10px;
        background: #fb2e34;
        display: inline-block;
        vertical-align: 52px;
      }

      span {
        display: block;
        font-family: Roboto;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: 0.8px;
        color: #fff;

      }

    }
  }
}

@media screen and (max-width: 767px) {
  .global-section {
    .global-img-mobile {
      display: block;
    }

    .global-img {
      display: none;
    }

    .global_header {
      width: 348px;
      margin: auto;

      h1 {
        font-family: Roboto;
        font-size: 32px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
        margin-top: 60px;
      }

      p {
        font-family: Roboto;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: 1.71px;
        text-align: center;
        color: #fff;
      }
    }

    .container {
      .web {
        display: none;
      }

      .list {
        width: 306px;

        .wow {
          display: flex;
          font-family: Roboto;
          font-size: 30px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #fff;
          padding: 0;


        }

        .top-element {
          margin-bottom: 35px;
        }

        .bottom-element {
          margin-top: 34px;
        }

        span {
          font-family: Roboto;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.38;
          letter-spacing: 1.5px;
          color: #fff;
        }

        span.plus {
          font-size: 30px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          margin-top: 0;
          margin-left: 8px;
          color: #fff;
        }

        span.text {
          font-family: Roboto;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.38;
          letter-spacing: 1.5px;
          color: #fff;
          margin-top: 0;
          margin-left: 15px;
          text-align: left;
          width: 150px;
        }

        span.text-adj {
          margin-left: 51px;
          max-width: 159px;
        }
      }

      img {
        height: 333px;
      }
    }
  }
}
</style>
