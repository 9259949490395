<template>
  <div class="carousel-awards">
    <a-carousel :after-change="onChange">
      <div class="item-1">
        <img
          src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/info-section/best-broker.webp"
        />
        <h3>"Nhà Môi Giới Hàng Đầu"</h3>
        <p>Giải Thưởng Thường Niên FOLLOWME 2020</p>
      </div>
      <div>
        <div class="item-1">
          <img
            src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/info-section/customer-service.webp"
          />
          <h3>“Dịch Vụ Khách Hàng <br/> Tuyệt Vời Nhất”</h3>
          <p>Cuộc Thi Giao Dịch Phái Sinh Toàn Cầu Năm 2020</p>
        </div>
      </div>
      <div>
        <div class="item-1">
          <img
            src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/info-section/financial-broker.webp"
          />
          <h3>"Nhà Môi Giới Tốt Nhất"</h3>
          <p>Giải Thưởng Vinh Danh Nhóm Ngành Công Nghệ Tài Chính 2020</p>
        </div>
      </div>
      <div>
        <div class="item-1">
          <img
            src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/info-section/excellent-broker.webp"
          />
          <h3>“Nhà Môi Giới Sở Hữu DịchVụ Khách Hàng Tuyệt Vời Nhất”</h3>
          <p>BXH Nhà Môi Giới FX168 2020 (Châu Á)</p>
        </div>
      </div>
      <div>
        <div class="item-1">
          <img
            src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/info-section/most-praised.webp"
          />
          <h3>"Nhà Môi Giới <br/> Được Yêu Thích Nhất"</h3>
          <p>Giải Thưởng Thường Niên Huiyou.com</p>
        </div>
      </div>
    </a-carousel>
  </div>
</template>

<script>
import "ant-design-vue/dist/antd.css";

export default {
  name: "AwardsCarousel",

  setup() {
    const onChange = (current) => {
      console.log(current);
    };

    return {
      onChange,
    };
  },
};
</script>
<style lang="less">
/* For demo */
.ant-carousel :deep(.slick-slide) {
  text-align: center;
  height: 160px;
  line-height: 160px;
  background: #364d79;
  overflow: hidden;
}

.ant-carousel :deep(.slick-slide h3) {
  color: #fff;
}

.ant-carousel {
  .slick-slider {
    .slick-dots {
      li {
        button {
          margin-left: 0;
          height: 8px;
          width: 8px;
          border-radius: 10px;
          background: red;
        }
      }
    }
  }
}

.carousel-awards {
  margin: auto;
  margin-bottom: 72px;

  .item-1 {
    img {
      display: inline;
      width: 300px;
      height: 300px;
    }
    h3 {
      font-family: Roboto;
      font-size: 20px;
      font-weight: bold;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: 1.88px;
      text-align: center;
      color: #fff;
    }
    p {
      font-family: Roboto;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 1.5px;
      text-align: center;
      color: #fff;
      margin: 0 auto;
      margin-top: 40px;
      margin-bottom: 21px;
      width: 262px;
    }
  }
}
</style>
