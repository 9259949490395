<template>
<div id="ĐĂNG-KÝ-NGAY">
<!-- <Header /> -->
<Banner />
<Informatics />
<Footer />
</div>
</template>

<script>

// import Header from '../components/header/index.vue'
import Banner from '../components/Banner.vue'
import Informatics from '../components/Informatics.vue'
import Footer from '../components/footer/index.vue'

export default{
    name: 'App',
    components: {
        Banner,
        Informatics,
        Footer
        // Header
    }
}
</script>

<style>

</style>