<template>
  <div id="register" class="brand_form wow fadeInUp" data-wow-delay="0.8s">
    <div class="promotion-form">
      <div class="blank" id="blank"></div>
      <div class="loading active">
        <div class="loading_bg">
          <span class="loading-ico"> <i></i><i></i><i></i><i></i> </span>
        </div>
      </div>
      <FormKit
        id="myForm"
        class="form"
        type="form"
        @submit="handleSubmit"
        :submit-attrs="{
          inputClass: 'btn red submit',
        }"
        submit-label="ĐĂNG KÝ NGAY"
        incomplete-message="Xin lỗi, các trường thông tin chưa chính xác"
      >
        <FormKit
          type="text"
          name="lastname"
          label="last name"
          placeholder="Họ"
          validation="required"
          :validation-messages="{ required: 'Họ là trường bắt buộc' }"
        />
        <FormKit
          type="text"
          name="firstname"
          label="first name"
          placeholder="Tên"
          validation="required"
          :validation-messages="{ required: 'Tên là trường bắt buộc' }"
        />
        <FormKit
          name="email"
          placeholder="Email"
          validation="required|email"
          :validation-messages="{ required: 'Vui lòng nhập email' }"
        />
        <div class="input-group">
          <FormKit
            v-model="values"
            type="select"
            name="uphonecode"
            :options="phoneinit"
          />
          <FormKit
            type="tel"
            name="phone"
            placeholder="Số điện thoại"
            minlength="9"
            maxlength="20"
            validation="required|matches:/^[0-9]+$/"
            :validation-messages="{
              required: 'Số điện thoại là bắt buộc',
              matches: 'Số điện thoại phải ở định dạng xxxxxxxxx',
            }"
          />
        </div>
      </FormKit>
      <p>
        <img v-bind:src="imageUrl + 'protection.svg'" class="protection" />
        Thông Tin Của Bạn Sẽ Được Bảo Mật
      </p>
      <div class="submit-message success">
        Cảm ơn bạn đã đăng ký. Chúng tôi sẽ liên hệ với bạn sớm nhất có thể.
      </div>
      <div class="submit-message error">
        Xin lỗi, các trường thông tin chưa chính xác
      </div>
    </div>
    <div id="modals"></div>
    <MyModal :formStatus="formSubmit" ref="childComponent" />
  </div>
</template>



<script type="text/babel">
import "@formkit/themes/genesis";
import MyModal from './Modal.vue'
import PhoneList from "../js/phonecode_en.json";
import { ref } from "vue";
export default {
  name: "formComponent",
  components: {
    MyModal
  },
  data() {
    return {
      imageUrl:
        "http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/kolobo/image/",
      isLoading: false,
      PhoneList,
      values: ref(["+84 Vietnam"]),
      phoneinit: [],
      formSubmit: false,
    };
  },
  methods: {
    handleSubmit(formValues) {
      let submitMessageSuccess = document.querySelector(
        ".submit-message.success"
      );
      let submitMessageError = document.querySelector(".submit-message.error");
      function setTimeoutFunc(target) {
        setTimeout(function () {
          target.classList.remove("active");
        }, 9000);
      }
      this.axios
        .post("/api/v1/mail/send", {
          formValues,
        })
        .then(() => {
          this.formSubmit = ref(true);
          this.$refs.childComponent.showModal();
          submitMessageError.classList.remove("active");
          submitMessageSuccess.classList.add("active");
          this.$formkit.reset("myForm");
          setTimeoutFunc(submitMessageSuccess);
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          submitMessageSuccess.classList.remove("active");
          submitMessageError.classList.add("active");
          setTimeoutFunc(submitMessageError);
        });
    },
    initPhoneCodeSelect() {
      let newarray = [];
      for (let i = 0; i < this.PhoneList.length; i++) {
        const phone = this.PhoneList[i];
        newarray.push(phone.value + " " + phone.name);
      }
      this.phoneinit = newarray;
    },
  },
  mounted() {
    this.initPhoneCodeSelect();
  },
};
</script>

<style lang="less">
[data-invalid] .formkit-inner {
  border-color: red;
  box-shadow: 0 0 0 1px red;
}
[data-type="submit"] .formkit-input {
  border-radius: 4px;
  background-image: linear-gradient(to bottom, #ff3641, #c9080d);
  margin-top: 35px;
  margin-right: 0;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.0;
  letter-spacing: normal;
  text-align: center;
  color: #fefefe;
  padding: 12px 0 16px;
  max-width: 418px;
  width: 100%;
  &:hover {
    background-image: linear-gradient(to bottom, #ff3641, #c9080d);
  }
}

/* remove blue color for chrome autocomplete*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.formkit-form {
  max-width: 866px;
  margin: auto;
    @media only screen and (max-width:1199px){
      max-width:311px;
    }
  > .formkit-outer {
    vertical-align: top;
    min-height: 84px;
    &:nth-child(odd) {
      margin-right: 15px;
      width: 50%;
      width: calc(50% - 15px);
      display: inline-block;
    }
    &:nth-child(even) {
      margin-left: 15px;
      width: 50%;
      width: calc(50% - 15px);
      display: inline-block;
    }
  }
  .formkit-wrapper {
    margin: 0 auto;
    max-width: 100%;
    .formkit-inner {
      border: 0;
      border-radius: 0;
    }
    .formkit-label {
      display: none;
    }
    input,
    select {
      background-color: #fdfdfd;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000018 !important;
      padding: 15px;
      border-radius: 0;
    }
    select {
      color: #000 !important;
      height: 55px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 92px;
    }
  }
  .formkit-messages {
    text-align: left;
  }
}

.input-group {
  position: relative;
  margin-left: 15px;
  width: 50%;
  width: calc(50% - 15px);
  display: inline-block;
  z-index: 0;
  .formkit-outer {
    &:first-child {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      width: 100px;
      .formkit-wrapper {
        .formkit-inner {
          border: solid 1px transparent;
          border-radius: 0;
          box-shadow: none;
        }
      }
      select {
        background: #fff;
        box-shadow: none;
      }
    }
    &:last-child {
      position: relative;
      input {
        padding-left: 108px;
      }
    }
  }
}
.promotion-form {
  p {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 1.75px;
    color: #fff;
    margin-top: 15px;
    margin-bottom: 0px;
  }
}
.form {
  .formkit-inner input {
    width: 100%;
    height: 57px;
    box-sizing: border-box;
    display: block;
    border: solid 1px #dbdbdb;
    background-color: #fdfdfd;
    overflow: hidden;
  }
  .btn_group {
    max-width: 418px;
    margin: auto;
    .submit {
      color: #fff;
      padding: 12px 30px 16px 30px;
      border-radius: 4px;
      background-image: linear-gradient(to bottom, #ff3641, #c9080d);
      margin-bottom: 15px;
      display: block;
    }
  }
  > p {
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #fff;
  }
  .protection {
    margin-right: 10px;
  }
  .form-item {
    display: flex;
    justify-content: space-between;
    width: 400px;
    color: #373737;
    flex-direction: column;
  }

  .form-item-content {
    display: flex;
    flex-direction: column;
    input {
      width: 100%;
      height: 100%;
      display: block;
      font-size: 14px;
      border: 0;
      outline: none;
      color: #6e6e6e !important;
      padding-left: 25px;
      box-sizing: border-box;
    }
    input[placeholder],
    [placeholder],
    *[placeholder],
    input::-webkit-input-placeholder {
      color: #747474;
    }
  }

  .form-select {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  .form-item-phone .form-input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .form-item-phone {
    width: 100%;
    height: 57px;
    box-sizing: border-box;
    display: flex;
    .form-select {
      width: 108px;
      color: #6e6e6e;
      line-height: 60px;
      padding: 10px;
      background: #fff;
    }
    .form_input {
      width: 67%;
    }
    .form-input {
      border: 0 !important;
      input {
        padding-left: 0 !important;
      }
    }
    .icon {
      display: inline-block;
      background: #ecf3f9;
      width: 26px;
      height: 26px;
      color: #6e6e6e;
      text-align: center;
      border-radius: 2px;
      line-height: 1.5;
      position: relative;
    }
  }

  .form-select {
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: space-between;
    font-size: 0.875rem;
    line-height: 26px;
    cursor: pointer;
    align-items: center;
    &.disabled {
      background-color: #d9d9d9;
      cursor: not-allowed;
    }
  }

  .form-select-option {
    position: absolute;
    display: none;
    top: 53px;
    left: -1px;
    width: 100%;
    min-width: 250px;
    border: 1px solid #e3e3e3;
    background: #fff;
    max-height: 250px;
    overflow-y: auto;
    z-index: 11;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
    li {
      box-sizing: border-box;
      padding: 0 16px;
      line-height: 28px;
      height: 28px;
      &:hover {
        background-color: #ecf6fd;
      }
    }
  }

  .form-item-explain {
    font-size: 0.75rem;
    line-height: 20px;
    color: #ff0000;
    margin: 0;
    height: 26px;
  }

  .btn_group {
    margin: 30px 0 20px;
    transform: scale(0.8);
    justify-content: center;
    .btn {
      font-size: 20px;
      background: #e61616;
      margin: 0;
      text-align: center;
      &:after {
        display: none;
      }
      span {
        width: auto;
        float: initial;
      }
    }
  }
}
.submit-message {
  padding: 12px 0 11px;
  border-radius: 25px;
  border: solid 1px #55c159;
  background-color: #222;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #55c159;
  max-width: 866px;
  margin: auto;
  display: none;
  opacity: 0;
  margin-top: 30px;
  &.error {
    border: solid 1px #ff0000;
    color: #ff0000;
  }
  &.active {
    display: block;
    opacity: 1;
    animation-name: messageFadeUp;
    -webkit-animation-name: messageFadeUp;
    animation-duration: 10s;
    animation-fill-mode: both;
    -webkit-animation-duration: 10s;
    -webkit-animation-fill-mode: both;
  }
}
@keyframes messageFadeUp {
  0% {
    transform: translate3d(0, 40px, 0);
  }
  20% {
    transform: translate3d(0, 0, 0);
  }
  80% {
    opacity: 1;
  }
  90% {
    transform: translate3d(0, -20, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, -20, 0);
    opacity: 0;
  }
}
@media only screen and (max-width: 1199px) {
  [data-type="submit"] .formkit-input {
    margin-top: 0;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height:1.0;
    letter-spacing: normal;
    text-align: center;
    color: #fefefe;
  }

  .formkit-form {
    .formkit-wrapper {
      input,
      select {
        padding: 12px 15px 13px;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #b5b5b5;
      }
      select {
        height: 45px;
      }
    }
    > .formkit-outer {
      &:nth-child(odd) {
        margin-right: 5px;
        width: calc(50% - 5px);
      }
      &:nth-child(even) {
        margin-left: 5px;
        width: calc(50% - 5px);
      }
    }
    .formkit-outer {
      margin: 0;
      min-height: 70px;
      &:nth-child(3) {
        display: block;
        width: 100%;
      }
    }
    .input-group {
      display: block;
      width: 100%;
      margin-left: 0;
    }
  }
  .formkit-actions .formkit-outer {
    min-height: auto;
  }

  .promotion-form > p {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    margin-bottom: 0px;
  }

  .submit-message {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #55c159;
    padding: 9px 5px;
    margin-top: 25px;
  }
}
</style>

