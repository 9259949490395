<template>
  <div class="header-main">
    <div class="header-container" id="nav_style">
      <div>
        <a href="https://www.dooprime.com/vn/">
        <img
          class="header-img"
          src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/info-section/banks/doo-header.png"
        />
        </a>
      </div>
      <div class="window-only"></div>
      <div class="window-only clickable"><a href="#Doo-Prime-Thế-Mạnh">Thế Mạnh</a></div>
      <div class="window-only clickable"><a href="#Giới-Thiệu">Giới Thiệu</a></div>
      <div class="window-only clickable"><a href="#Nguồn-Vốn">Nguồn Vốn</a></div>
      <div class="nav-register clickable"><a href="#ĐĂNG-KÝ-NGAY"> ĐĂNG KÝ NGAY </a></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "headerComponent",
};

window.onscroll = () => {
 let navStyle = document.getElementById("nav_style");
    if (window.scrollY < 110) {
      navStyle.style.backgroundColor = "transparent"
    } else {
      navStyle.style.backgroundColor = "black"
  }
};

</script>

<style lang="less">
.header-main {
  font-family: roboto;

.nav-fixed {
    width: 100% !important;
    position: fixed !important;
    transition: 0.3s ease-in-out !important;
}

.nav-active {
    background-color:red !important;
    box-shadow: 5px -1px 12px -5px grey !important;
}

  // max-width:1473px;
  // margin-left:245px;
  // margin-right:219px;
  margin-bottom: 140px;
  height: 50px;
  .header-container {
    display: flex;
    height:100px;
    align-content:center;
    flex-wrap: wrap;
    padding-left: 235px;
    position: fixed;
    z-index:10;
    width: 100%;
    top: 0;
    margin-top: 0px;

    .header-img {
      width: 202px !important;
      height: 48px;
    }

    :nth-child(1) {
      width: 20%;
      align-content: left;
      text-align: left;
    }
    :nth-child(2) {
      width: 20%;
    }
    :nth-child(n + 3) {
      width: 12%;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: right;
      line-height: 3;
      color: #fff;
    }
    :nth-child(6) {
      border-radius: 3px;
      background-color: #d91d22;
      text-align: center;
      // padding: 10px 30px 11px 37px;
      height: 48px;
      width: 237px;
      line-height:2.5;
      margin-left: 54px;
      font-size: 20px;
      font-weight: bold;
      font-style: normal;
      padding-bottom: 2px;
      letter-spacing: 1.5px;
      color: #fefefe;
    }

    .clickable{
        a{
            text-decoration: none;
            color: inherit;
        }
    }
  }

  @media screen and (max-width: 767px) {
    .window-only {
      display: none;
    }
    .header-container {
      display: flex;
      justify-content: space-around;
      padding-left: 0;
      max-width: 100%;
      margin-top: 0px;
      .header-img {
        width: 135px !important;
        height: 32px;
      }
      :nth-child(6) {
        width: 140px;
        height: 40px;
        padding-left: 10px;
        line-height:3.5;
        border-radius: 3px;
        background-color: #d91d22;
        font-size: 12px;
        max-width: 100%;
      }
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 1562px) {
  .header-main {
    .header-container {
      :nth-child(2) {
        display: none;
      }
      
    }
    .header-container {
      .header-img {
        width: 135px !important;
        height: 32px;
      }
      display: flex;
      flex-wrap: wrap;
      padding-left: 0;
      position: fixed;
      z-index:10;
      width: 100%;
      top: 0;
      margin-top: 0px;
      padding-right: 0;
      justify-content: center;
      :nth-child(n + 3) {
        width: 14%;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        line-height: 3;
        color: #fff;
      }
      :nth-child(6) {
        width: 156px;
      }
    }
  }
}
//   @media screen and (max-width: 1418px) {
//     .header-main {
//       .header-container {
//         :nth-child(2) {
//           display: none;
//         }
//       }
//       .header-container {
//         display: flex;
//         flex-wrap: wrap;
//         padding-left: 0;
//         position: absolute;
//         width: 100%;
//         top: 0;
//         margin-top: 46px;
//         padding-right: 0;
//         justify-content: center;
//         :nth-child(n + 3) {
//           width: 14%;
//           font-size: 16px;
//           font-weight: bold;
//           font-stretch: normal;
//           font-style: normal;
//           line-height: normal;
//           letter-spacing: normal;
//           line-height: 3;
//           color: #fff;
//         }
//       }
//     }
//   }
//   @media screen and (max-width: 1562px) {
//     .header-main {
//       .header-container {
//         :nth-child(2) {
//           display: none;
//         }
//       }
//       .header-container {
//         display: flex;
//         flex-wrap: wrap;
//         padding-left: 0;
//         position: absolute;
//         width: 100%;
//         top: 0;
//         margin-top: 46px;
//         padding-right: 0;
//         justify-content: center;
//         :nth-child(n + 3) {
//           width: 14%;
//           font-size: 16px;
//           font-weight: bold;
//           font-stretch: normal;
//           font-style: normal;
//           line-height: normal;
//           letter-spacing: normal;
//           line-height: 3;
//           color: #fff;
//         }
//       }
//     }
//   }
</style>
