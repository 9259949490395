<template>
  <button>
    <slot></slot>
  </button>
</template>

<style lang="less">
button {
  width: 311px;
  font-size: 16px;
  font-family: Roboto;
  font-weight: bold;
  height: 48px;
  margin: 16px 8px 0 8px;
  border-radius: 2px;
  border: solid 1px #f1f1f1;
  background-color: #fff;
}

</style>
