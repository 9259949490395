<template>
  <div class="bank-section">
    <h1 id="Nguồn-Vốn">Tiếp Cận Nguồn Vốn Linh Hoạt</h1>
    <p>
      Doo Prime cung cấp nhiều phương thức nạp tiền an toàn, dễ dàng và nhanh
      chóng với độ bảo mật cao.
    </p>

    <div class="container-bank">
      <div>
        <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/info-section/banks/paypal.svg" />
      </div>
      <div>
        <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/info-section/banks/skrill.svg" />
      </div>
      <div>
        <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/info-section/banks/neteller.svg" />
      </div>
      <div>
        <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/info-section/banks/web-money.svg" />
      </div>
      <div>
        <img
          src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/info-section/banks/mastercard.svg" />
      </div>
      <div>
        <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/info-section/banks/fasapay.svg" />
      </div>
      <div>
        <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/info-section/banks/epay.svg" />
      </div>
      <div>
        <img class="pay-crypto"
          src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/info-section/banks/pay-crypto.webp" />
      </div>
      <div>
        <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/info-section/banks/otc-365.svg" />
      </div>
      <div>
        <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/info-section/banks/flashex.svg" />
      </div>
      <div></div>
      <div>
        <img
          src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/info-section/banks/ngan-luong.svg" />
      </div>
      <div>
        <img
          src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/info-section/banks/thunderxpay.svg" />
      </div>
      <div>
        <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/info-section/banks/pay-trust.svg" />
      </div>
      <div></div>
    </div>

    <div class="container-bank-mobile">
      <div class="row-1">
        <div class="row-1-item-1">
          <img
            src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/info-section/wire-transfer.webp" />
        </div>
        <div class="row-1-item-2">
          <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/info-section/bank-transfer.svg" />
        </div>
        <div class="row-1-item-3">
          <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/info-section/visa.svg" />
        </div>
        <div class="row-1-item-4">
          <img
            src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/info-section/banks/mastercard.svg" />
        </div>
      </div>

      <div class="row-2">
        <div class="row-2-item-1">
          <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/info-section/banks/paypal.svg" />
        </div>
        <div class="row-2-item-2">
          <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/info-section/banks/skrill.svg" />
        </div>
        <div class="row-2-item-3">
          <img
            src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/info-section/banks/neteller.svg" />
        </div>
      </div>

      <div class="row-3">
        <div class="row-3-item-1">
          <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/info-section/banks/fasapay.svg" />
        </div>

        <div class="row-3-item-2">
          <img
            src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/info-section/banks/web-money.svg" />
        </div>

        <div class="row-3-item-3">
          <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/info-section/banks/epay.svg" />
        </div>
      </div>

      <div class="row-4">
        <div class="row-4-item-1">
          <img class="pay-crypto"
            src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/info-section/banks/pay-crypto.webp" />
        </div>
        <div class="row-4-item-2">
          <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/info-section/banks/otc-365.svg" />
        </div>
        <div class="row-4-item-3">
          <img
            src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/info-section/banks/thunderxpay.svg" />
        </div>
      </div>

      <div class="row-5">
        <div class="row-5-item-1">
          <img
            src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/info-section/banks/pay-trust.svg" />
        </div>
        <div class="row-5-item-2">
          <img src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/info-section/banks/flashex.svg" />
        </div>
        <div></div>
        <div class="row-5-item-3">
          <img
            src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/info-section/banks/ngan-luong.svg" />
        </div>
      </div>
    </div>
    <div class="container-lower">
      <h1>Hỗ Trợ Nhiều Loại Tiền Tệ</h1>
      <p>
        AED, AUD, BRL, CLP, CNY, COP, EUR, EUSD, GBP, HKD, IDR, JPY, KHR, KRW,
        LAK, MMK, MXN, NGN, PEN, THB, USD, USDT, VND, ZAR
      </p>
      <div class="lower-border">
        <div class="icon-box"></div>
      </div>
    </div>

    <div class="container-bottom">
      <h1>Phương Thức Thanh Toán</h1>
      <div class="button-section">
        <button-template>Ví Điện Tử</button-template>
        <button-template>Chuyển Khoản Ngân Hàng Quốc Tế</button-template>
        <button-template class="button-3">Chuyển Khoản Ngân Hàng Việt Nam</button-template>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonTemplate from "./UI/ButtonTemplate.vue";

export default {
  components: { ButtonTemplate },
  name: "FundsSection",
  component: {
    ButtonTemplate,
  },
};
</script>

<style lang="less">
.bank-section {
  .container-bank-mobile {
    display: none;
  }

  h1 {
    font-size: 48px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    margin-bottom: 19px;

  }

  p {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 1.71px;
    text-align: center;
    color: #fff;
    margin-bottom: 67px;
  }

  .container-bank {
    display: grid;
    grid-template-columns: 15% 15% 15% 15% 15%;
    grid-template-rows: auto;
    justify-content: center;
    row-gap: 40px;
    margin-bottom: 73px;

    div {
      max-width: 178px;

      .pay-crypto {
        width: 167px;
        height: 36px;
      }
    }

    @media screen and (max-width: 834px) {
      grid-template-columns: none;
    }
  }

  .container-lower {
    h1 {
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.8px;
      text-align: center;
      color: #fff;
      margin-bottom: 17px;
    }

    p {
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.22;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
      margin-bottom: 48px;
    }
  }

  .lower-border {
    border-top: red 1px solid;
    max-width: 1070px;
    width: 80%;
    margin: auto;
    margin-top: 48px;
    height: 1px;

    @media screen and (max-width: 834px) {
      width: 90%;
    }

    .icon-box {
      width: 10px;
      height: 10px;
      background: #fb2e34;
      display: inline-block;
      vertical-align: 52px;
      transform: rotate(-315deg);
      position: relative;
      top: -6px;
    }
  }

  .container-bottom {
    h1 {
      margin-top: 32px;
      margin-bottom: 23px;
      font-family: Roboto;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.8px;
      text-align: center;
      color: #fff;
    }

    .button-section {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      line-height: 32px;
      padding-bottom: 169px;

      :nth-child(1) {
        width: 208px;
      }

      div {
        max-width: 308px;
        border: #f1f1f1 solid 1px;
        border-radius: 2px;
        background-color: #ffffffff;
        height: 24px;
        text-align: center;
        padding: 10px 10px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .bank-section {
    h1 {
      font-family: Roboto;
      font-size: 32px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      text-align: center;
      color: #fff;
      margin: auto;
      margin-bottom: 16px;
      width: 237px;
    }

    p {
      font-family: Roboto;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: 1.71px;
      text-align: center;
      color: #fff;
      margin-top: 16px;
      width: 348px;
      margin: auto;
      margin-bottom: 32px;
    }

    .container-bank {
      display: none;
    }

    .container-bank-mobile {
      display: block;
      gap: 24px;

      .row-1 {
        width: 100%;
        display: flex;
        justify-content: space-around;

        .row-1-item-1 {
          img {
            height: 24px;
            width: 68px;
          }
        }

        .row-1-item-2 {
          img {
            height: 24px;
            width: 38px;
          }
        }

        .row-1-item-3 {
          img {
            height: 24px;
            width: 54px;
          }
        }

        .row-1-item-4 {
          img {
            height: 24px;
            width: 27px;
          }
        }
      }

      .row-2 {
        width: 100%;
        display: flex;
        justify-content: space-around;
        margin-top: 24px;

        .row-2-item-1 {
          img {
            width: 68px;
            height: 24px;
          }
        }

        .row-2-item-2 {
          img {
            width: 70px;
            height: 24px;
            margin-left: 32px;
          }
        }

        .row-2-item-3 {
          img {
            width: 147px;
            height: 24px;
            margin-left: 32px;
          }
        }
      }

      .row-3 {
        width: 100%;
        display: flex;
        justify-content: space-around;
        margin-top: 24px;

        .row-3-item-1 {
          img {
            height: 24px;
            width: 111px;
          }
        }

        .row-3-item-2 {
          img {
            height: 24px;
            width: 105px;
            margin-left: 32px;
          }
        }

        .row-3-item-3 {
          img {
            height: 24px;
            width: 86px;
            margin-left: 32px;
          }
        }
      }

      .row-4 {
        width: 100%;
        display: flex;
        justify-content: space-around;
        margin-top: 24px;

        .row-4-item-1 {
          img {
            height: 24px;
            width: 111px;
          }
        }

        .row-4-item-2 {
          img {
            height: 24px;
            width: 86px;
          }
        }

        .row-4-item-3 {
          img {
            height: 24px;
            width: 127px;
          }
        }
      }

      .row-5 {
        width: 100%;
        display: flex;
        justify-content: space-around;
        margin-top: 24px;
        margin-bottom: 54px;

        .row-5-item-1 {
          img {
            height: 24px;
            width: 75px;
          }
        }

        .row-5-item-2 {
          img {
            height: 24px;
            width: 119px;
          }
        }

        .row-5-item-3 {
          img {
            height: 24px;
            width: 103px;
          }
        }
      }
    }

    .container-lower {
      h1 {
        font-family: Roboto;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.8px;
        text-align: center;
        color: #fff;
        width: 227px;
        margin: auto;
      }

      p {
        font-family: Roboto;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
        margin-top: 8px;
        margin-bottom: 0;
      }

      .lower-border {
        margin-top: 20px;
      }
    }

    .container-bottom {
      h1 {
        font-family: Roboto;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
        width: 226px;
        margin-bottom: 9px;
      }

      .button-section {
        gap: 0;

        :nth-child(1) {
          width: 311px;
        }

        padding-bottom: 72px;
      }
    }
  }
}
</style>
