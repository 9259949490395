<template>
  <div>
    <div class="partner_top">
      <h1>Đối Tác Truyền Thông</h1>
      <div class="partner_flex">
        <div class="row-1">
          <div class="row-1-item-1">
            <img
              src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/info-section/finance.svg"
            />
          </div>
          <div class="row-1-item-2">
            <img
              src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/info-section/investing.svg"
            />
          </div>
          <div class="row-1-item-3">
            <img
              src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/info-section/myfxbook.svg"
            />
          </div>
        </div>

        <div class="row-2">
          <div class="row-2-item-1">
            <img
              src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/info-section/fx110.svg"
            />
          </div>
          <div class="row-2-item-2">
            <img
              src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/info-section/wikifx.svg"
            />
          </div>
          <div class="row-2-item-3">
            <img
              src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/info-section/paxnet.svg"
            />
          </div>
        </div>

        <div class="row-3">
          <img
            src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/info-section/traderviet.svg"
          />
        </div>
      </div>
    </div>
    <div class="partner_middle">
      <h1>Các Giải Thưởng</h1>
      <p>
        Theo đuổi sự hoàn mỹ và nỗ lực không ngừng để trở thành thương hiệu giao
        dịch hàng hóa hàng đầu thế giới
      </p>
    </div>

    <div class="awards">
      <div class="awards-1 hide">
        <img
          src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/info-section/best-broker.webp"
        />
        <h3>"Nhà Môi Giới Hàng Đầu"</h3>
        <p>Giải Thưởng Thường Niên FOLLOWME 2020</p>
      </div>
      <div class="awards-2 hide">
        <img
          src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/info-section/customer-service.webp"
        />
        <h3>"Dịch Vụ Khách Hàng <br/> Tuyệt Vời Nhất"</h3>
        <p>Cuộc Thi Giao Dịch Phái Sinh Toàn Cầu Năm 2020</p>
      </div>
      <div class="awards-3 hide">
        <img
          src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/info-section/financial-broker.webp"
        />
        <h3>"Nhà Môi Giới Tốt Nhất"</h3>
        <p>Giải Thưởng Vinh Danh Nhóm Ngành Công Nghệ Tài Chính 2020</p>
      </div>
      <div class="awards-4 hide">
        <img
          src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/info-section/excellent-broker.webp"
        />
        <h3>"Nhà Môi Giới Sở Hữu Dịch Vụ Khách Hàng Tuyệt Vời Nhất"</h3>
        <p>BXH Nhà Môi Giới FX168 2020 (Châu Á)</p>
      </div>
      <div class="awards-5 hide">
        <img
          src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/info-section/most-praised.webp"
        />
        <h3>"Nhà Môi Giới <br/> Được Yêu Thích Nhất"</h3>
        <p>Giải Thưởng Thường Niên Huiyou.com</p>
      </div>
    </div>
    <div class="carousel">
      <AwardsCarousel />
    </div>
  </div>
</template>

<script>
import AwardsCarousel from "./CarouselAwards/Index.vue";

export default {
  name: "partnerSection",
  components: {
    AwardsCarousel,
  },
};
</script>

<style lang="less">
.partner_flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin-bottom: 160px;
  .row-1 {
    display: flex;
    .row-1-item-2 {
      img {
        margin-left: 32px;
      }
    }
    .row-1-item-3 {
      img {
        margin-left: 32px;
      }
    }
  }
  .row-2 {
    display: flex;
    .row-2-item-1 {
      img {
        margin-left: 32px;
      }
    }
    .row-2-item-2 {
      img {
        margin-left: 32px;
      }
    }
    .row-2-item-3 {
      img {
        margin-left: 32px;
      }
    }
  }
  .row-3 {
    display: flex;
    img {
      margin-left: 48px;
    }
  }
}

.partner_top {
  h1 {
    font-size: 48px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.5px;
    text-align: center;
    color: #fff;
    margin-top: 146px;
    margin-bottom: 57px;
  }
}

.partner_middle {
  h1 {
    font-size: 48px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.5px;
    text-align: center;
    color: #fff;
    margin-top: 0px;
    margin-bottom: 16px;
  }
  p {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 1.71px;
    text-align: center;
    color: #fff;
    margin-bottom: 64px;
  }
}

.awards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-bottom: 160px;

  div {
    width: 300px;
    h3 {
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 28px;
      letter-spacing: 0.8px;
      text-align: center;
      color: #fff;
    }
    p {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 1.5px;
      text-align: center;
      color: #fff;
    }
    img {
      width: 300px;
      height: 300px;
    }
  }
}
.carousel {
  display: none;
}



@media screen and (max-width: 767px) {
  .partner_top {
    h1 {
      font-family: Roboto;
      font-size: 32px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1px;
      text-align: center;
      color: #fff;
      margin-top: 9px;
    }
    .partner_flex {
      margin-bottom: 72px;
      .row-1 {
        width: 90%;
        display: flex;
        justify-content: space-around;
        .row-1-item-1 {
          img {
            width: 59px;
            height: 24px;
          }
        }
        .row-1-item-2 {
          img {
            width: 119px;
            height: 24px;
          }
        }
        .row-1-item-3 {
          img {
            width: 86px;
            height: 24px;
          }
        }
      }

      .row-2 {
        width: 100%;
        display: flex;
        justify-content: space-around;
        margin-top: 32px;
        .row-2-item-1 {
          img {
            width: 104px;
            height: 24px;
            margin-left: 0;
          }
        }
        .row-2-item-2 {
          img {
            width: 96px;
            height: 24px;
            margin-left: 0;
          }
        }
        .row-2-item-3 {
          img {
            width: 81px;
            height: 24px;
            margin-left: 0;
          }
        }
      }
      .row-3 {
        margin-top: 32px;
        img {
          width: 147px;
          height: 24px;
          margin-left: 0;
        }
      }
    }
  }
  .partner_middle {
    margin: auto;
    width: 348px;
    h1 {
      font-family: Roboto;
      font-size: 32px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
      margin-top: 0;
    }
    p {
      font-family: Roboto;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: 1.71px;
      text-align: center;
      color: #fff;
      margin-bottom: 0;
    }
  }
  .awards {
    margin-bottom: 72px;
    .hide {
      display: none;
    }
    .awards-1 {
      margin: auto;
      img {
        height: 300px;
        width: 300px;
      }
      h3 {
        font-family: Roboto;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: 1.88px;
        text-align: center;
        color: #fff;
        margin-top: 8px;
      }
      p {
        font-family: Roboto;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 1.5px;
        text-align: center;
        color: #fff;
        margin: 0 auto;
        margin-top: 40px;
        width: 262px;
      }
    }
  }
  .carousel {
    display: block;
  }
}
</style>
