<template>
  <section id="header" class="header">
    <Header />
    <div class="container">
      <h1 class="wow fadeInUp">GIAO DỊCH VỚI HƠN 10.000 SẢN PHẨM CFD</h1>
      <div class="list">
        <div class="wow fadeInUp" data-wow-delay="0.2s">
          60 +<span>Cặp Tiền Tệ</span>
        </div>
        <div class="icon-box"></div>
        <div class="wow fadeInUp" data-wow-delay="0.4s">
          300 +<span>Cổ Phiếu Mỹ - Hồng Kông</span>
        </div>
        <div class="icon-box web"></div>
        <div class="wow fadeInUp" data-wow-delay="0.6s">
          50 ms<span>Độ Trễ Cực Thấp</span>
        </div>
        <div class="icon-box"></div>
        <div class="wow fadeInUp" data-wow-delay="0.6s">
          0.1 pips<span>Mức Spread Cực Thấp</span>
        </div>
      </div>
    </div>
      <Form />
  </section>
</template>

<script>
import Header from "./header/index.vue";
import Form from "./Form.vue";

export default {
  name: "BannerComponent",
  components: {
    Header,
    Form,
  },
};
</script>

<style lang="less">
@cdn: "http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/";
@import url('https://fonts.googleapis.com/css?family=Poppins');


.header {
  height: 900px;
  padding-top: 72px;
  background: url("@{cdn}ahgd-header.png") no-repeat center;
  background-size: cover;
  h1 {
    font-size: 54px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 2.02px;
    text-align: center;
    font-family:Roboto !important;
    color: #fff;
    margin-top: 0;
    margin-bottom: 40px;
  }
  .list {
    max-width: 100%;
    width: 100%;
    margin: auto;
    margin-bottom: 70px;
    div {
      &:not(.icon-box) {
        display: inline-block;
        font-size: 40px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        font-family:Roboto;
        text-align: center;
        color: #fff;
        padding: 0 40px;
      }
      &.icon-box {
        width: 10px;
        height: 10px;
        background: #fb2e34;
        display: inline-block;
        vertical-align: 52px;
      }
      span {
        display: block;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
        margin-top: 8px;
      }
    }
  }
}

@media only screen and (max-width: 1199px) {
  .header {
    padding-top: 89px;
    padding-bottom: 95px;
    height: auto;
    h1 {
      font-size: 36px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
    }
    .list {
      div {
        &:not(.icon-box) {
          font-size: 30px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          font-family:Roboto;
          text-align: center;
          color: #fff;
        }
        &.icon-box {
          vertical-align: 35px;
        }
        span {
          font-size: 18px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #fff;
          margin-top: 4px;
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .web {
    display: none !important;
  }
  .header-main {
    height: 0;
    margin-bottom: 0px;
  }
  .header {
    h1 {
      font-family: Roboto-Bold;
      max-width: 285px;
      line-height: 35px;
      letter-spacing: 0.9px;
      text-align: center;
      font-size: 24px;
      margin: 42px auto;
    }
    .list {
      display: grid;
      grid-template-columns: 1fr 0.5fr 1fr;
      grid-template-rows: 1fr 1fr;
      margin-top: 48px;
      margin-bottom: 20px;
      max-width: 281px;
      div {
        &:not(.icon-box) {
          font-family: Poppins;
          font-size: 30px;
          text-align: left;
          padding: 0;
        }
        &.icon-box {
          margin-top: 13px;
          margin-left: 10%;
        }
        span {
          font-family: Roboto;
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.38;
          letter-spacing: normal;
          color: #fff;
          text-align: left;
          margin-top: 11px;
          margin-bottom: 17px;
        }
      }
    }
    .mobile {
      display: block;
      margin-bottom: 50px;
      font-size: 30px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
      span {
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
        display: block;
      }
    }
  }
}
</style>
