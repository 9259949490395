<template>
  <div>
    <div class="info_header">
      <h1 id="Doo-Prime-Thế-Mạnh">
        Tại Sao Nên Chọn <span class="window-only"><br /></span>
        Doo Prime?
      </h1>
      <p>
        Nhà môi giới tài chính trực tuyến cung cấp môi trường giao dịch hàng đầu cho các nhà đầu tư
      </p>
    </div>
    <div class="info_box">
      <div>
        <div class="icon shield">
          <img
            src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/info-section/icon-features-licensed-supervision.svg" />
        </div>
        <h1>Quy Định Nghiêm Ngặt</h1>
        <p>
          Doo Prime được quản lý chặt chẽ bởi các tổ chức tài chính đa quốc gia
          như Seychelles FSA, Mauritius FSC và Vanuatu FSC.
        </p>
      </div>
      <div>
        <div class="icon">
          <img
            src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/info-section/icon-product-stock.svg" />
        </div>
        <h1>Mức Spread Cực Thấp</h1>
        <p>
          Chúng tôi cung cấp chi phí giao dịch cực thấp với mức spread chỉ 0.1
          pips.
        </p>
      </div>
      <div>
        <div class="icon">
          <img
            src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/info-section/icon-features-trade.svg" />
        </div>
        <h1>Hơn 10,000 <br> Sản Phẩm Giao Dịch</h1>
        <p>
          Tận hưởng vô số cơ hội giao dịch với quyền truy cập vào hơn 10.000 sản
          phẩm CFD.
        </p>
      </div>
      <div>
        <div class="icon">
          <img
            src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/info-section/icon-features-service.svg" />
        </div>
        <h1>24*7*365 Hỗ Trợ Khách Hàng Chuyên Nghiệp</h1>
        <p>
          Doo Prime tự hào sở hữu đội ngũ với hơn 500 chuyên gia được đào tạo
          chuyên nghiệp để cung cấp dịch vụ hỗ trợ khách hàng hàng đầu trong
          suốt 365 ngày.
        </p>
      </div>
      <div>
        <div class="icon">
          <img
            src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/info-section/icon-features-tech.svg" />
        </div>
        <h1>Cực Kỳ Nhanh Chóng</h1>
        <p>
          Sự hỗ trợ mạnh mẽ của cơ sở hạ tầng và mạng CNTT từ Equinix cho phép
          99,5% giao dịch được thực hiện chỉ trong 0.05 giây.
        </p>
      </div>
      <div>
        <div class="icon">
          <img
            src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/ahgd580/imgs/info-section/icon-features-1-500.svg" />
        </div>
        <h1>Đòn Bẩy 1:500</h1>
        <p>
          Bắt đầu với số vốn thấp và tối đa hóa lợi nhuận cho giao dịch của bạn
          với đòn bẩy linh hoạt lên đến 1:500.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InfoSection",
};
</script>

<style lang="less">
.info_header {
  font-size: 48px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: white;
  margin-top: 0px;
  margin-bottom: 72px;
  padding-top: 120px;

  h1 {
    margin-bottom: 16px;
    color: #fff;
  }

  #Doo-Prime-Thế-Mạnh {
    font-family: Roboto;
    font-size: 48px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: rgba(255, 255, 255, 0.95);
    @media screen and (max-width: 767px) {
      font-size: 32px;
    }
  }

  p {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 1.5px;
    text-align: center;
    color: rgba(255, 255, 255, 0.95);
    margin-top: 0px;
    margin-bottom: 72px;
  }

  .window-only {
    display: none;
  }

  div {
    width: 433px;
  }
}

.info_box {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 65px;
  width: 80%;
  margin: auto;

  @media screen and (max-width: 835px) {
    margin-left: 0;
    gap: 0;
    justify-content: center;
    max-width: 100%;

    h1 {
      max-width: 100%;
    }
  }

  h1 {
    width: 345px;
    text-align: left;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(255, 255, 255, 0.95);
  }

  :nth-child(3) {
    h1 {
      width: 433px !important;
    }
  }

  div {
    width: 348px;
    margin-right: 40px;
    text-align: left;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.8px;
    color: rgba(199, 202, 210, 0.95);
  }
}

@media screen and (max-width: 767px) {
  .info_header {
    width: 348px;
    margin: auto;
    padding-top: 64px;

    h1 {
      font-family: Roboto;
      font-size: 32px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: rgba(255, 255, 255, 0.95);
      width: 254px;
      margin: auto;
    }

    p {
      font-family: Roboto;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: 1.5px;
      text-align: center;
      color: rgba(255, 255, 255, 0.95);
      margin-top: 8px;
    }
  }

  .info_box {
    width: 348px;
    margin: auto;

    div {
      margin-right: 0;
    }

    .icon {
      display: flex;
      justify-content: center;
    }

    h1 {
      font-family: Roboto;
      font-size: 28px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: rgba(255, 255, 255, 0.95);
      width: 300px;
      margin: auto;
    }

    p {
      font-family: Roboto;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: 1.5px;
      text-align: center;
      color: rgba(199, 202, 210, 0.95);
      margin: 0 auto;
      margin-top: 15px;
      margin-bottom: 20px;
    }
  }
}
</style>
